<template>
  <div ref="iframeContainer" :style="attribute.style">
    <iframe
      v-if="isIframeVisible"
      ref="iframeElement"
      :src="attribute.src || src"
      :style="attribute.style"

      class="webView"
    ></iframe>
  </div>
</template>

<script>
export default {
  props: {
    attribute: {
      type: Object,
      default: () => ({
        src: "",
        style: {
          width: "100%",
          height: "80vh"
        }
      })
    },
    src: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      isIframeVisible: false // 用于控制 iframe 是否显示
    };
  },
  mounted() {
    this.$nextTick(() => {
      const observer = new IntersectionObserver(
        (entries) => {
          const entry = entries[0];
          if (entry.isIntersecting) {
            // 当 iframe 进入视口时显示它
            if (this.attribute.src || this.src) {
              this.isIframeVisible = true;
            } else {
              this.$watch('attribute.src', (newSrc) => {
                if (newSrc) {
                  this.isIframeVisible = true;
                }
              });
            }
          } else {
            // 当 iframe 离开视口时销毁它
            this.isIframeVisible = false;
          }
        },
        { threshold: 0.1 }
      );
      observer.observe(this.$refs.iframeContainer); // 观察外层容器
    });
  },
  beforeDestroy() {
    // 在组件销毁之前，确保 observer 断开连接
    if (this.observer) {
      this.observer.disconnect();
    }
  }
};
</script>

<style scoped lang="scss">
.webView {
  width: 100%;
  height: 77vh;
  opacity: 1;
  transition: opacity 0.5s ease-in-out; /* 过渡效果 */
}
</style>
