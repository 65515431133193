<!--
 * @Author: jiang
 * @Date: 2021-06-14 08:48:32
 * @Description: 附件上传
-->
<template>
    <div>
      <el-upload
        action="false"
        :accept="acceptList"
        :file-list="fileList"
        :http-request="onUploadMedia"
        :before-remove="onRemove"
      >
        <el-button
          size="small"
          type="primary"
          :disabled="mediaList.length==num"
        >点击上传</el-button>
      </el-upload>
    </div>
  </template>
  
  <script>
  import { uploadMedia, removeMedia } from '@/api/common/upload'
  export default {
    props: {
      value: {},
      default: {},
      flag:{
        type: String,
        default: () => "0"
      },
      num:{
        type:Number,
        default:999
      },
      acceptList: {
            type: String,
            default: () => [
          'application/msword',
          'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
          'application/pdf',
          'application/vnd.ms-powerpoint',
          'image/*',
          'text/plain',
          'audio/mp4',
          'video/mp4',
          'audio/mpeg',
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
          'application/vnd.ms-excel',
          'text/csv',
          'application/ofd',
          'application/kswps',
          'application/kset',
          'application/ksdps',
        ].join(','),
        },
    },
    data() {
      return {
        // acceptList: [
        //   'application/msword',
        //   'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
        //   'application/pdf',
        //   'application/vnd.ms-powerpoint',
        //   'image/*',
        //   'text/plain',
        //   'audio/mp4',
        //   'video/mp4',
        //   'audio/mpeg',
        //   'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        //   'application/vnd.ms-excel',
        //   'text/csv',
        //   'application/ofd',
        //   'application/kswps',
        //   'application/kset',
        //   'application/ksdps',
        // ].join(','),
        mediaList: [],
        fileList: [],
      }
    },
    mounted(){
     
    },
    watch: {
      default: {
        handler(val) {
          if (val) {
            console.log(val)
            // name: item.original_filename + '.' + item.extension,
            this.fileList = val.map(item => {
              return {
                flag: false,
                key: item.id?item.id:item.media_key,
                name: item.original_filename?item.original_filename:item.name,
                url: item.url,
              }
            })
            this.mediaList = []
            val.forEach(element => {
              this.mediaList.push({
                flag: false,
                media_key:element.media_key,
                extension:element.extension,
                original_filename: element.original_filename?element.original_filename:element.name,
                url: element.url,
              })
            });
          }
        },
        immediate: true,
      },
    },
    methods: {
      onRemove(file, fileList) {
       
        return this.$confirm('确定要移除附件吗？', '提示', { type: 'warning' }).then(() => {
        
          if (file.flag == true) {
            const index = this.mediaList.findIndex(item => item.url === file.url)
            return removeMedia(file.key).then(() => {
              this.mediaList.splice(index, 1)
              his.$emit('input', this.mediaList)
              return true
            })
          } else {
           
            const index = this.mediaList.findIndex(item => item.url === file.url)
            this.mediaList.splice(index, 1)
            this.$emit('input', this.mediaList)
            return true
          }
        })
      },
      onUploadMedia(data) {
        console.log(this.mediaList)
        uploadMedia(data.file)
          .then(res => {
             this.$message({
                      message: '上传成功',
                      type: 'success'
                    });
            data.onSuccess(res.media_key)
            if(this.flag=='1'){
             
              this.mediaList.push({
                original_filename:res.original_name,
                url:res.url,
                extension:res.extension,
                media_key:res.media_key
              })
              
            }else{
              this.mediaList.push(res.media_key)
             
            }
            this.$emit('input', this.mediaList)
            // this.$emit('default', this.mediaList)
            
          })
          .catch(() => {
            this.$message.error('上传失败');
            data.onError()
          })
      },
    },
  }
  </script>
  <style scoped lang="scss">
  /deep/ .el-upload{
    text-align: left;
  }
  </style>
  