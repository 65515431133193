<!--
 * @Description:
 * @Author: YangXin
 * @Date: 2024-08-09 18:41:34
 * @listEditTime: Do not edit
-->
<template>
    <el-dialog :close-on-click-modal="false" title="修改标题"  width="30%" :append-to-body="true" :lock-scroll="false" center :modal="false" @close="closeFunc" :visible="true">
        <span>标题名称</span>
        <el-input v-model="title"></el-input>
        <span slot="footer" class="dialog-footer">
            <el-button @click="closeFunc">取 消</el-button>
            <el-button type="primary" @click="editTitleFunc">确 定</el-button>
        </span>
    </el-dialog>
</template>

<script>
export default {
    data(){
        return {
            title:''
        }
    },
    methods:{
        editTitleFunc:function(){
            console.log(this.$root.titleEditIndex)
            let data = this.$parent.currentDepartment[this.$root.titleEditIndex.prentId].children[this.$root.titleEditIndex.indexBlock]
            console.log(data)

            data.name = this.title
            this.$parent.titleDjalogType = false
            console.log(data)


        },
        closeFunc(){

            this.$parent.titleDjalogType = false
        }
    }
}
</script>

<style></style>
