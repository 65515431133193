<!--
 * @Description:
 * @Author: YangXin
 * @Date: 2024-08-07 15:32:12
 * @listEditTime: Do not edit
-->
<template>
  <el-dialog title="编辑模块" :append-to-body="true" :lock-scroll="false" modal :close-on-click-modal="false"
    @close="closeFunc" :visible="videoEditDialogType" top='5vh'>
    <el-card class="box-card">
      <div class="card-item">
        <span class="label">展示形式</span>
        <el-radio-group v-model="imgType">
          <el-radio label="pic-list">视频列表形式</el-radio>
          <el-radio label="carousel">轮播形式</el-radio>
        </el-radio-group>
        <div v-if="imgType == 'carousel'">
          <span class="label" style="margin-left: 50px;">轮播时间</span>
          <el-input-number v-model="carouselTime" :min="0" :max="10"></el-input-number>
        </div>
      </div>
      <div class="card-item">
        <span class="label">视频尺寸</span>
        <el-radio-group v-model="imgSize">
          <el-radio label="self-adapt">{{ imgType == 'pic-list' ? '默认尺寸' : '自适应尺寸' }}</el-radio>
          <el-radio label="self-custom">自定义尺寸</el-radio>
        </el-radio-group>
        <div class="setSize" v-if="imgSize == 'self-custom'">
          <div>宽 <el-input-number v-model="imgwidth" :min="250" :max="3900"></el-input-number> px</div>
          <div>高 <el-input-number v-model="imgheight" :min="250" :max="3900"></el-input-number> px</div>
        </div>
      </div>
      <div class="card-item">
        <el-button @click="saveCard" type="primary">保 存</el-button>
      </div>
    </el-card>
    <div class="editMain">
      <div class="menuList">
        <div class="navBox" @click="addItemFunc">
          + 添加视频
        </div>
        <div class="bttomBox">
          <div v-for="(item, index) in imgList" :key="index" class="itemBox"
            :class="{ 'itemBox2': editListMode.id === item.id }" @click="getItemFunc(item, index)">
            <VideoPlayer :refsName="'myVideo' + item.id" :src="item.url" v-if="dragItemName == '视频'"></VideoPlayer>
            <div class="topMenu" v-show="editListMode.id === item.id">
              <el-dropdown trigger="click">
                <span class="el-dropdown-link">
                  <el-button>编辑</el-button>
                </span>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item @click.native="onTheMove(item, index)">上移动</el-dropdown-item>
                  <el-dropdown-item @click.native="underTheMove(item, index)">下移动</el-dropdown-item>
                  <!-- <el-dropdown-item @click.native="delBlockItemFunc(index)">删除</el-dropdown-item> -->
                </el-dropdown-menu>
              </el-dropdown>
            </div>
          </div>
        </div>
      </div>
      <div class="editMenu">
        <el-form v-if="imgList.length" label-width="120px" :model="editListMode" style="height: 100%" ref="imgFromList"
          :rules="rules">
          <el-form-item label="标题名称" prop="title">
            <el-input v-model="editListMode.title"></el-input>
          </el-form-item>
          <el-form-item label="排序" prop="sort">
            <el-input-number v-model="editListMode.sort" :min="1"></el-input-number>
          </el-form-item>
          <el-form-item label="视频" prop="linkType">
            <el-upload class="upload-demo" style="height: 100px;width: 200px;" drag action="" :show-file-list="false"
              :http-request="onUpload" :on-change="handleChange">
              <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>

            </el-upload>
            <div class="el-upload__tip" slot="tip">只能上传jpg/png文件，且不超过500kb</div>
            <el-progress :percentage="processlength" v-show="showProcess" :stroke-width="3"></el-progress>
          </el-form-item>
          <el-form-item>
            <el-button @click="savefunc()">保存</el-button>

            <el-button style="margin-left: 30rpx" @click="delBlockItemFunc">
              删除
            </el-button>
          </el-form-item>
        </el-form>
      </div>
    </div>
  </el-dialog>
</template>

<script>
import { Editor, Toolbar } from '@wangeditor/editor-for-vue'
import { uploadImage, uploadMedia } from '@/api/common/upload'
import pdf from 'vue-pdf'
import VideoPlayer from "../vueVideoPlayer/videoPlayer"
import "@wangeditor/editor/dist/css/style.css"
import CUploadImage from "@/components/upload/image.vue";
import CUploadMedia from "@/components/upload/video.vue";
import EditorTextbus from "@/components/editor/textbus/index.vue";
import { nanoid } from "nanoid";
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';
import { createPdfs, deletePdfs, updatePdfs } from '@/api/news/index'
export default {
  components: { Editor, Toolbar, VideoPlayer, pdf, CUploadImage, EditorTextbus, CUploadMedia },
  props: {
    cardIndex: {
      type: Object,
      default: () => ""
    },
    videoEditDialogType: {
      type: Boolean,
      default: () => false
    }
  },
  data() {
    return {

      editListMode: {
        id: 0,
        title: "",
        src: "",
        linkUrl: '',
        sort: 1,
        linkType: 'noLink'
      },
      cardList: "",
      cardChild: "",
      textItemList: [],
      editor: null,
      mapping: {},
      mediaList: {},
      mode: 'default', // or 'simple'
      nameType: "",
      imgList: [],
      videoFileList: [],
      FileList: [],
      // 表单校验
      rules: {},
      chooseindex: null,
      postId: '',//当前id
      showProcess: false,//是否显示进度条
      processlength: 0,
      interval: null,
      imgType: 'pic-list',//展示形式
      imgSize: 'self-adapt',//图片尺寸
      carouselTime: 1,//；轮播时间
      imgwidth: '250',
      imgheight: '250'
    }
  },
  mounted() {
    console.log(111)
    this.init()

  },

  computed: {
    ...mapState('user', {
      userId: state => state.userInfo.id,
      userName: state => state.userInfo.name,
      userDepartmentId: state => state.userInfo.department.id,
      userDepartmentName: state => state.userInfo.department.name,
    }),
    dragItemName() {

      return this.$parent.dragItemName?.name
    },
    /**
     * @Author: YangXin
     * @Description: 从store获取元素
     * @Date: 2024-08-07 18:23:53
     * @return {*}
     */
    imgTextList() {
      return this.$store.getters.imgTextList;
    },
    currentDepartment() {
      return this.$store.getters["schema/schema"].value
    },
    ...mapGetters({
      getDataMapByKey: 'schema/dataMapByKey',    // 将 store.getters.count 映射到 this.count
      getData: 'schema/dataMap'
    })

  },


  methods: {
    /**
     * @Author: YangXin
     * @Description: 初始化获取修改的数组
     * @Date: 2024-08-07 18:18:03
     * @return {*}
     */
    init: function () {
      if (this.cardIndex) {
        this.cardList = this.currentDepartment[this.cardIndex.dataCardIndex],
          this.cardChild = this.cardList.children[this.cardIndex.cardTwoIndex]
      } else {
        this.cardList = this.currentDepartment[this.$parent.formListMode.dataCardIndex],
          this.cardChild = this.cardList.children[this.$parent.formListMode.cardChildIndex]
      }
      this.imgType = this.cardChild.imgType
      this.carouselTime = this.cardChild.carouselTime
      this.imgSize = this.cardChild.imgSize
      this.imgwidth = this.cardChild.imgwidth
      this.imgheight = this.cardChild.imgheight
      if (this.cardChild.children) {
        if (this.cardChild.children[0].type == "videoItem") {
          console.log(this.cardChild.children[0])
          this.$parent.dragItemName = { name: "视频" }
          this.imgList = this.cardChild.children[0].vodeoList
        }
        this.editListMode = this.imgList[0]
      } else {
        this.imgList = []
      }

    },

    /**
     * @Author: YangXin
     * @Description: 添加图片
     * @Date: 2024-08-07 18:21:32
     * @return {*}
     */
    addItemFunc: function () {

      console.log(1111)
      if (this.dragItemName == "视频") {
        this.imgList.unshift({
          id: this.imgList.length,
          title: "",
          url: '',
          sort: 1,
          saveType: false,

        })
      }

      // if (this.imgList.length == 1) {
      this.editListMode = this.imgList[0]

      // }

    },
    /**
     * @Author: YangXin
     * @Description: 上移动
     * @Date: 2024-08-15 14:52:12
     * @param {*} item
     * @param {*} index
     * @return {*}
     */
    onTheMove: function (item, index) {
      this.imgList.splice(index - 1, 0, item)
      this.imgList.splice(index + 1, 1)
    },

    /**
     * @Author: YangXin
     * @Description: 下移动
     * @Date: 2024-08-15 14:52:33
     * @param {*} item
     * @param {*} index
     * @return {*}
     */
    underTheMove: function (item, index) {
      this.imgList.splice(index + 2, 0, item)
      this.imgList.splice(index, 1)
    },

    /**
     * @Author: YangXin
     * @Description: 删除元素
     * @Date: 2024-08-15 14:58:00
     * @param {*} index
     * @return {*}
     */
    delBlockItemFunc: function () {
       let item = this.editListMode

        this.imgList.splice(this.chooseindex, 1)
        if (this.imgList.length !== 0) {
          this.editListMode = this.imgList[0]
        }


    },
    /**
     * @Author: YangXin
     * @Description: 上传图片
     * @Date: 2024-08-13 16:32:47
     * @param {*} data
     * @return {*}
     */
    onUpload: function (data) {

      if (this.dragItemName == "视频") {
        uploadMedia(data.file)
          .then(res => {
            this.editListMode.url = res.url
            this.processlength = 100

            this.$message({ message: '上传成功', type: 'success' });
            setTimeout(() => {
              this.showProcess = false
              clearInterval(this.interval)
            }, 500)

          })
      }
    },
    handleChange(file, fileList) {
      if (file.status === 'ready') {
        this.processlength = 0
        this.showProcess = true
        this.interval = setInterval(() => {
          if (this.processlength >= 90) {
            clearInterval(this.interval)
            return
          }
          this.processlength += 1
        }, 20)
      }
      console.log(file.status)
      if (file.status === 'success') {
        this.processlength = 100
        this.showProcess = false
      }
    },
    // 上传图标
    IcononUpload(data) {
      uploadImage(data.file, progress => {
        if (progress.total > 0) {
          progress.percent = (progress.loaded / progress.total) * 100
        }
        data.onProgress(progress)
      }).then(res => {
        this.editListMode.icon = res.image_url,

          this.$message({ message: '上传成功', type: 'success' });
      })
    },
    /**
     * @Author: YangXin
     * @Description: 上传图片回显
     * @Date: 2024-08-07 18:18:07
     * @param {*} res
     * @param {*} file
     * @return {*}
     */
    handleAvatarSuccess(res, file) {
      this.editListMode.images = URL.createObjectURL(file.raw);
    },
    /**
     * @Author: YangXin
     * @Description:
     * @Date: 2024-08-07 18:18:12
     * @param {*} editor
     * @return {*}
     */
    onCreated(editor) {
      this.editor = Object.seal(editor)
    },
    /**
     * @Author: YangXin
     * @Description: 点击获取修改的item
     * @Date: 2024-08-08 09:07:28
     * @param {*} item
     * @return {*}
     */
    getItemFunc: function (item, index) {
      this.editListMode = item
      this.videoFileList = item.mediaList
      this.chooseindex = index

    },
    //  保存卡片信息
    saveCard() {
      if (!this.cardChild.children) {
        this.$message({
          message: '请添加图片',
          type: 'warning'
        });
        return;
      }
      this.cardChild.imgType = this.imgType
      this.cardChild.carouselTime = this.carouselTime
      this.cardChild.imgSize = this.imgSize
      this.cardChild.imgwidth = this.imgwidth
      this.cardChild.imgheight = this.imgheight
      this.$message({
          message: '保存成功',
          type: 'success'
        });
    },
    /**
     * @Author: YangXin
     * @Description:
     * @Date: 2024-08-08 10:17:20
     * @return {*}
     */
    savefunc: function () {
      let item = null
      this.imgList.map((res, index) => {
        if (res.id == this.editListMode.id && res.saveType == false) {
          this.imgList[index].saveType = true
          item = res
        } else {
          item = res
        }
      })

      if (item) {

        if (this.cardChild.children && this.cardIndex?.type !== true) {
          if (this.dragItemName == "视频") {
            this.cardChild.children[0].vodeoList = this.imgList
          }
        } else if (this.cardChild.children == null || this.cardChild.children == '') {
          this.cardChild.children = []
          if (this.dragItemName == "视频") {
            this.cardChild.children.push({
              "type": "videoItem",
              vodeoList:this.imgList
            })
          }

        } else {

        }
        console.log(this.cardChild.children)
        this.$message({
          message: '保存成功',
          type: 'success'
        });

        // call(this)

      }

      // let item = this.getDataMapByKey(this.nameType)[this.editListMode.id]

      // item.title = this.editListMode.title
      // item.src = this.editListMode.images
      // item.isSave = true
    },

    /**
     * @Author: YangXin
     * @Description:
     * @Date: 2024-08-08 10:41:22
     * @return {*}
     */
    closeFunc: function () {
      // console.log(this.cardChild.children)
      // this.imgTextList.data = this.textItemList
      // this.cardChild.children = [JSON.parse(JSON.stringify(this.imgTextList))]
      this.$parent.videoEditDialogType = false
    },
    sortBy(attr, rev) {
      //第二个参数没有传递 默认升序排列
      if (rev == undefined) {
        rev = 1;
      } else {
        rev = (rev) ? 1 : -1;
      }

      return function (a, b) {
        a = a[attr];
        b = b[attr];
        if (a < b) {
          return rev * -1;
        }
        if (a > b) {
          return rev * 1;
        }
        return 0;
      }
    }

  },


}
</script>

<style lang="scss" scoped>
/deep/ .el-dialog {
  width: 80% !important;
  border-radius: 10px;
  background: #F0F0F0;


}

.upload-demo {
  /deep/ .el-upload {
    height: 100%;
    width: 100%;

    .el-upload-dragger {
      height: 100%;
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }

  }
}


.titleBox {
  width: 100%;
  display: flex;
  padding-top: 10px;

}

// background: #F0F0F0;
// border-radius: 10px;
/deep/ .avatar-uploader .el-upload {
  border: 1px dashed #9c2b2b !important;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

/deep/ .avatar-uploader .el-upload:hover {
  border-color: #409EFF !important;
}

/deep/ .avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}

.editMain {
  display: flex;
  width: 100%;
  height: 100%;
  position: relative;

  .menuList {
    width: 30%;
    padding-top: 10px;
    padding: 10px;
    background: #ffffff;
    // border: 1px solid black;
    height: 825px;

    .navBox {
      cursor: pointer;
      height: 60px;
      width: 100%;
      text-align: center;
      line-height: 60px;
      font-size: 16px;
      font-family: Arial, Helvetica, sans-serif;
      border-radius: 8px;
      color: #0074AB;
      border: 1px solid #0074AB;
    }

    .navBox:hover,
    .itemBox:hover {
      background: #F0F0F0;
    }

    .bttomBox {
      padding-top: 10px;
      height: calc(100% - 60px);
      overflow-y: scroll !important;
      /* 或者 overflow-x: scroll; 根据需要选择 */
    }

    .itemBox {
      width: 100%;
      cursor: pointer;
      position: relative;
      height: 150px;
      padding: 5px 8px;
      border-radius: 4px;
      display: flex;
      border: 1px solid #0074AB;

    }

    .itemBox2 {
      background: #F0F0F0;

      // box-shadow: 0px 18px 15px -3px rgba(0, 0, 0, 0.1);
    }
  }

  .editMenu {
    padding-top: 10px;
    margin-left: 10px;
    background: #ffffff;
    height: 825px;
    width: 69%;
    padding: 14px 20px;


    .avatar {
      width: 178px;
      height: 178px;
      display: block;
    }
  }
}

.topMenu {
  top: 0;
  right: 0;
  position: absolute;
}

.box-card {
  margin-bottom: 15px;

  /deep/ .el-card__body {}

  .card-item {
    margin-bottom: 10px;
    display: flex;
    align-items: center;

    .label {
      margin-right: 10px;
    }

    &:last-child {
      margin-bottom: 0;
      margin-top: 15px;
    }
  }
}
.setSize {
  display: flex;
  margin-left: 10px;

  >div {
    margin-right: 10px;
  }
}
</style>