<template>
  <fragment class="engineMain">
    <div class="clase-all">
      <el-button @click="closeAll" type="primary">{{showCard?'收缩':'展开'}}所有卡片</el-button>
    </div>

    <Vuedraggable :list="data" :group="engineGroup" :sort="bigPictureEdit" :disabled="!bigPictureEdit" style="display: flex;" :axis="'x'" handle=".drag-handle" :class="dashed?'dashline':''">
      <data-card :name="card['name']" ref="cardParentRef" :cardType="card['type']" v-for="(card, index) in data"
        :key="index" :style="'cursor: move;'+card?.dataCardStyle" :dataCardIndex="index" class="drag-handle">
       
        <Vuedraggable :list="card['children']" :group="engineChildrenGroup" :disabled="!bigPictureEdit"
          style="display: flex;justify-content: center;height: 100%;cursor: move"  :axis="'x'"   handle=".drag-handle" :sort="bigPictureEdit" @add="childrenAddItem"
          class="trans">
          <div v-for="(block, cardChildIndex) in card['children']" :key="cardChildIndex" class="block-box">
            <data-block :name="block['name']" :width="block['width']" v-if="block['type'] === 'block'"
              :cardIndexId="{ prentId: index, indexBlock: cardChildIndex }" style="cursor: move;" class="drag-handle">

              <Vuedraggable :list="block['children']" :group="blockChildrenGroup" :cardChildIndex="cardChildIndex"
                :dataCardIndex="index" style="display: flex;justify-content: center;height: 100%;" :sort="false" :axis="'x'"
                ref="ref">
                <div v-for="(element, blackChildrenIndex) in block['children']" :key="blackChildrenIndex"
                  @mouseleave="handleMouseLeave"
                  @mouseenter.stop="eidtBtnDisplay(index,element )" style="width: 100%;cursor: move;"   class="drag-handle">
                  <div class="childrenTopMenu" v-show="element === topMenuType" v-if="bigPictureEdit">
                    <el-dropdown trigger="click">
                      <span class="el-dropdown-link">
                        <el-button>编辑</el-button>
                      </span>
                      <el-dropdown-menu slot="dropdown">
                        <el-dropdown-item  v-if="element['type'] === 'web-view' || element['type'] === 'JumpImg'"
                          @click.native="editUrlIndex(block, blackChildrenIndex)">编辑url</el-dropdown-item>
                          
                          <el-dropdown-item  v-if="element['type'] === 'views'"
                          @click.native="editUrlSIndex({ dataCardIndex: index, cardTwoIndex: cardChildIndex,type:true}, blackChildrenIndex)">编辑url</el-dropdown-item>

                          <el-dropdown-item  v-if="element['type'] === 'img'"
                          @click.native="imgEditor({ dataCardIndex: index, cardTwoIndex: cardChildIndex,type:true}, blackChildrenIndex)">图片编辑器</el-dropdown-item>
                                                    <el-dropdown-item  v-if="element['type'] === 'videoItem'"
                          @click.native="editVideoSIndex({ dataCardIndex: index, cardTwoIndex: cardChildIndex,type:true }, blackChildrenIndex)">视频编辑器</el-dropdown-item>
                          <el-dropdown-item  v-if="element['type'] === 'pdf-viewer'"
                          @click.native="pdfEditor({ dataCardIndex: index, cardTwoIndex: cardChildIndex,type:true }, blackChildrenIndex)">pdf编辑器</el-dropdown-item>
                        <el-dropdown-item  v-if="element['type'] === 'graphic-list'"
                          @click.native="OnlineEditor({ dataCardIndex: index, cardTwoIndex: cardChildIndex ,type:true}, blackChildrenIndex)">在线编辑器</el-dropdown-item>
                        <el-dropdown-item @click.native="delBlockItemFunc(block, blackChildrenIndex)">删除</el-dropdown-item>
                      </el-dropdown-menu>
                    </el-dropdown>
                  </div>
                  <web-view v-if="element['type'] === 'web-view'" :src="element['src']"
                    :height="element['height'] || '1058px'" :width="element['width']" />
                    <!-- 多链接 -->
                     <div v-if="element['type'] === 'views'" class="links-box">
                       <div >
                         <div class="links-left" >
                             <div @click="chooseLink(linksItem,linksIndex)" v-for="(linksItem,linksIndex) in element['linkList']"
                             :class="linksIdx==linksIndex?'linksActive':''">
                              {{ linksItem.title }}
                             </div>
                         </div>
                         <div class="links-right" v-if="linksObj">
                           <web-view :src="linksObj.url" v-if="linksObj.linkType=='web-view'"></web-view>
                           <img v-else  :src="linksObj.imageUrl"  :height="element['height']||1058" :width="element['width']"
                           @click="gotoUrl(linksObj.url)"  :alt="linksObj.title||block['name']"  class="JumpImg" mode="aspectFit"/>
                         </div>
                         <div class="links-right" v-else>
                           <web-view :src="element['linkList'][0].url" v-if="element['linkList'][0].linkType=='web-view'"></web-view>
                           <img v-else  :src="element['linkList'][0].imageUrl"  :height="element['height']||1058" :width="element['width']"
                           @click="gotoUrl(element['linkList'][0].url)"  :alt="element['linkList'][0].title||block['name']"  class="JumpImg"
                           mode="aspectFit"/>
                         </div>
                        </div>
                     </div>
                    <div  v-if="element['type'] === 'img'" style="height: 100%;width: 100%;">
                      <imgList :imgList="element['imgList']" :cardData="block"></imgList>
                    </div>
                   
                <map-marker-cluster :data="getDataMapByKey(element['dataSources'])"
                    v-if="element['type'] === 'map-marker-cluster'&&element['dataSources'].name!='wtphResultData'" :cardData="block" :element="block"/> 
                    <echart-map  :data="getDataMapByKey(element['dataSources'])"
                    v-if="element['type'] === 'map-marker-cluster'&&element['dataSources'].name=='wtphResultData'" :cardData="block" :element="block"></echart-map>
                    <img v-if="element['type']==='JumpImg'"  :src="element['src']"  :height="element['height']||1058" :width="element['width']"
                         @click="gotoUrl(element['clickUrl'])"  :alt="element['name']||block['name']"  class="JumpImg"/>
                  <videojs v-if="element['type'] === 'videoItem'" :vodeoList="element['vodeoList']" :cardData="block"></videojs>
                  <graphic-list :data="getDataMapByKey(element['dataSources'])"
                                :mapping="element['mapping']" :block="block"
                    :style="element['style']" @click="element?.clickEvent" :total="element['dataSources']"
                    v-if="element['type'] === 'graphic-list'" :cardIndexId="{ prentId: index, indexBlock: cardChildIndex }"></graphic-list>
                  <articleDetails v-if="element['type'] === 'articleDetails'"></articleDetails>
                  <pdf-viewer :srcList="element['srcList']"  :title="element['title']"
                    v-if="element['type'] === 'pdf-viewer'" :carWidth="block" :style="element['style']"
                    @click="element?.clickEvent"></pdf-viewer>
                </div>
              </Vuedraggable>

            </data-block>
            <div v-if="block['type'] === 'div'" :style="{ 'width': block['width'] }">
              <div v-for="(element, index) in block['children']" :key="index">
                <web-view v-if="element['type'] === 'web-view'" :src="element['src']"
                  :height="element['height'] || '1058px'" :width="element['width']" />
                  
             
                <!-- <map-marker-cluster :data="getDataMapByKey(element['dataSources'])"
                  v-if="element['type'] === 'map-marker-cluster'" /> -->
                  <drill-down-map  :data="getDataMapByKey(element['dataSources'])"
                    v-if="element['type'] === 'map-marker-cluster'" :cardData="block" :element="block"></drill-down-map>
                <graphic-list :data="getDataMapByKey(element['dataSources'])" :mapping="element['mapping']"
                  v-if="element['type'] === 'graphic-list'" :style="element['style']"
                  @click="element['clickEvent']" :cardIndexId="{ prentId: index, indexBlock: cardChildIndex }"></graphic-list>
                <pdf-viewer :srcList="element['srcList']" :title="element['title']"
                  v-if="element['type'] === 'pdf-viewer'" :style="element['style']"
                  @click="element['clickEvent']"></pdf-viewer>
              </div>
            </div>
          </div>
        </Vuedraggable>
     
      </data-card>
    </Vuedraggable>
<!--    <data-card name="其他">-->
<!--      <data-block name="外链平台">-->
<!--        <links />-->
<!--      </data-block>-->
<!--    </data-card>-->

  </fragment>
</template>

<script>


import DataCard from "./components/card"
import DataBlockTabs from "./components/block/block_tabs"
import DataBlock from "./components/block/block"
import Search from "./components/search/search"
import GraphicList from "@/views/data/components/graphicList/graphicList";
import DataVideos from "@/views/data/components/videos/videos";
import WebView from "@/views/data/components/webView/webView"
import Atlas from "@/views/data/components/atlas/atlas"
import MarkerCluster from "./components/maps/markerCluster"
import echartMap from "./components/maps/echartMap"
import pdfViewer from './components/pdf-viewer/listPdf'
import links from './components/links/index'
import Vuedraggable from 'vuedraggable' // 添加拖拽组件
import articleDetails from "./components/articleDetails/articleDetails"
import videojs from "./components/videos/videos.vue"
import imgList from "./components/imgList/imgList.vue"

import { apiMixin } from './mixin/api.mixin';
import { mapGetters, mapState } from "vuex";
import store from "@/store";
import { gsap } from "gsap-trial";



export default {
  props: {
    data: {
      type: Array,
      default: () => []
    },
    dataCardStyle: {
      type: String,
      default: () => ""
    }
  },
  data() {
    return {
      currentTime: new Date().toLocaleString(),
      pdfUrls: ["/mock/yzt/内蒙古自治区种业布局图放大版.pdf"],
      pdfCurrentDepartment: {},
      pdfCurrentTitle: "",
      engineGroup: { name: 'dataCardItem', pull: false, put: true },
      engineChildrenGroup: { name: 'dataCardItemChildren', pull: false, put: false },
      blockChildrenGroup: { name: 'blockChildren', pull: false, put: false },
      active: "",
      topMenuType: "",
      inputData: "",
      urlSelectId: "",
      num:0,

      options: [
        {
          value: '1',
          label: '内嵌网页'
        }, {
          value: '2',
          label: '跳转网页'
        }, {
          value: '3',
          label: '链接列表'
        },
      ],
      linksObj:null,
      linksIdx:0,
      dashed:false,
      showCard:false
    }
  },
  mixins: [],
  components: {
    links,
    pdfViewer,
    "map-marker-cluster": MarkerCluster,
    "graphic-list": GraphicList,
    DataVideos,
    WebView,
    Atlas,
    DataCard, Search,
    DataBlock,
    DataBlockTabs,
    Vuedraggable,
    articleDetails,
    videojs,
    imgList,
    echartMap

  },
  computed: {
    ...mapGetters({
      getDataMapByKey: 'schema/dataMapByKey',

      bigPictureEdit: 'bigPictureEditType'
    })
  },
  mounted() {
    let that = this;
    this.$watch('data', (newVal, oldVal) => {
      setTimeout(() => {

       
      }, 2000);
    }, { immediate: true, deep: true });
  },
  methods: {
    gotoUrl(url) {
      
      var newWindow = window.open(url, '_blank');
      newWindow.onload = function() {
          newWindow.location = url; // 确保使用HTTPS URL
      };
    },
    pdfClick(e) {
      this.pdfUrls = []
      this.pdfUrls = ["/mock/yzt/" + e.fileContent];
      this.pdfCurrentDepartment = e.deptName;
      this.pdfCurrentTitle = e.title;
    },

    /**
     * @Author: YangXin
     * @Description: 通过获取vue虚拟dom来修改data数据
     * @Date: 2024-08-05 10:27:39
     * @param {*} e
     * @return {*}
     */
    childrenAddItem(e) {
      console.log(e)
      let typeObject = {
        name: "",
        children: "",
        type: "block",
        width: "2000px",
        dragName: '',
        isStop:false,
        imgType: 'pic-list',//展示形式
        imgSize: 'self-adapt',//图片尺寸
        carouselTime: 1,//；轮播时间
        imgwidth: '250',
        imgheight: '250'
      }
      const scrollContainer = this.$parent.$refs["main-body"];
      // 方式1 操作vue 虚拟dom

      this.data[e.to.__vue__.$parent.$attrs.dataCardIndex]["dataCardStyle"] = ""
      this.data[e.to.__vue__.$parent.$attrs.dataCardIndex]['type'] = "card"
      if (e.item.innerText == "大卡片") {
        typeObject.dragName = "大卡片"
        this.data[e.to.__vue__.$parent.$attrs.dataCardIndex].children.splice(e.newDraggableIndex , 0, typeObject)
      }
      if (e.item.innerText == "小卡片") {
        typeObject.dragName = "小卡片"
        typeObject.width = "400px"
         typeObject.isStop = false

        this.data[e.to.__vue__.$parent.$attrs.dataCardIndex].children.splice(e.newDraggableIndex, 0, typeObject)
      }
      // this.$parent.contentChanged();

      // 方式2 操作真实dom
      //  let parentNode = e.to.parentNode
      //  parentNode.style = ""
      //  parentNode.setAttribute("class","card");
    },


    /**
     * @Author: YangXin
     * @Description: 创建虚拟dom
     * @Date: 2024-08-12 09:25:47
     * @return {*}
     */

    render(h) {
      return h('div', null, [
        // h('div', {
        //   style: "height:30px;width:100%;margin-bottom:5px;",
        // }, [
        //   h('span', {
        //     style: "display: inline-block;width:23%;",
        //   }, "修改id："),
        //   h('el-input', {
        //     style: "height:100%;width:70%;",
        //     props: {
        //       value: this.inputData
        //     },
        //     on: {
        //       input: (value) => { // 使用传统函数表达式来确保this指向Vue实例
        //         this.inputData = value;
        //       }
        //     }
        //   }),

        // ]),
        h('div', {
          style: "height:30px;width:100%;margin-bottom:5px;",
        }, [
          h('span', {
            style: "display: inline-block;width:23%;",
          }, "修改url地址："),
          h('el-input', {
            ref: 'urlInputRef',
            style: "height:100%;width:70%;",
            props: {
              value: this.urlInput
            },
            on: {
              input: (value) => { // 使用传统函数表达式来确保this指向Vue实例
                this.urlInput = value;
                this.$refs.urlInputRef.value = value
              }
            }
          }),

        ]),
        // h('div', {
        //   style: "display: inline-block;width:22%;height:30px;width:100%;",
        // }, [
        //   h('span', {
        //     style: "display: inline-block;width:23%;",
        //   }, "模块分类："),
        //   h('el-select', {
        //     ref: 'sendMsg',
        //     style: "height:100%;width:70%;",
        //     props: {
        //       value: this.urlSelectId
        //     },

        //     on: {

        //       change: (event) => {
        //         this.urlSelectId = event
        //         this.$refs['sendMsg'].value = event;

        //       }
        //     }
        //   }, [
        //     this.options.map(option =>
        //       h('el-option', {
        //         key: option.value,
        //         props: {
        //           label: option.label,
        //           value: option.value
        //         },
        //       })
        //     ),
        //   ]),

        // ]),
      ])


    },

    /**
     * @Author: YangXin
     * @Description: 编辑url弹窗
     * @Date: 2024-08-10 16:39:41
     * @return {*}
     */
    editUrlIndex(item, index) {
      if(item.children[index].type == "web-view"){
           this.urlInput = item.children[index].src
      }
      if(item.children[index].type == "JumpImg"){
          this.urlInput = item.children[index].clickUrl
      }

      const h = this.$createElement;
      this.$msgbox({
        title: 'url修改',
        message: this.render(h),
        showCancelButton: true,
        confirmButtonText: '确定',
        cancelButtonText: '取消',
      }).then(action => {

        if(item.children[index].type == "web-view"){
            item.children[index].src = this.urlInput
        }
        if(item.children[index].type == "JumpImg"){
            item.children[index].clickUrl = this.urlInput
        }
        this.$message({
          type: 'info',
          message: "修改成功"
        });
      });
    },

    /**
     * @Author: YangXin
     * @Description: 在线编辑器弹窗
     * @Date: 2024-08-10 19:57:34
     * @return {*}
     */
    OnlineEditor(item) {

      this.$parent.$refs.editor.cardItemObject = item
      this.$parent.$refs.editor.imgTextEditType = true
    },

    imgEditor(item){
      this.$parent.$refs.editor.cardItemObject = item
      this.$parent.$refs.editor.imagesEidtDialogType = true
    },

    editUrlSIndex(item){
      this.$parent.$refs.editor.cardItemObject = item
      this.$parent.$refs.editor.urlEditDialogType = true
    },

    pdfEditor(item){
      this.$parent.$refs.editor.cardItemObject = item
      this.$parent.$refs.editor.pdfEditDialogType = true
    },

    editVideoSIndex(item){
      this.$parent.$refs.editor.cardItemObject = item
      this.$parent.$refs.editor.videoEditDialogType = true
    },



    /**
     * @Author: 添加孙子删除
     * @Description:
     * @Date: 2024-08-10 16:24:32
     * @return {*}
     */
    delBlockItemFunc(item) {
      this.$confirm('此操作将移除框架中的模块, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          item.children = ""
          this.$message({
            type: 'success',
            message: '删除成功!'
          });
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          });
        });
    },


    /**
     * @Author: YangXin
     * @Description: 移出事件
     * @Date: 2024-08-10 15:38:04
     * @return {*}
     */

    handleMouseLeave: function () {

      this.topMenuType = ""
    },

    /**
     * @Author: YangXin
     * @Description:  移入事件
     * @Date: 2024-08-10 15:38:46
     * @param {*} index
     * @param {*} childIndex
     * @param {*} childrenIndex
     * @return {*}
     */
    eidtBtnDisplay(index, item) {
      console.log('编辑')
      this.$refs.cardParentRef[index].editType = false
      this.topMenuType = item
    },
    closeAll(){
      if(this.showCard){
        this.$refs.cardParentRef.forEach((item=>{
        item.childRen = false
      }))
      this.showCard = false
      }else{
        this.$refs.cardParentRef.forEach((item=>{
        item.childRen = true
      }))
      this.showCard = true
      }
      
      
    },
    // 选择链接
    chooseLink(item,index){
      this.linksObj = item
      this.linksIdx = index
    }
  }
}


</script>



<style scoped lang="scss">
.block-box{
  &:last-child{
    div{
      margin-right: 0;
    }
  }
}
.childrenTopMenu {
  position: absolute;

  z-index: 99;

}
.JumpImg{
  width: 100%;
  height: 100%;
}
.width-transition-enter-active, .width-transition-leave-active {
  transition: all 1s;
}
.width-transition-enter, .width-transition-leave-to  {
  width: 0;
}

  // .engineMain{
  //   overflow-x: scroll;
  // }
.links-box{
   height: 100%;
>div{
  display: flex;
  height: 100%;
}
  .links-left{
    margin-top: 14px;
    width: 15%;
    margin-right: 10px !important;
    
     div{
         height: 60px;
         line-height: 60px;
         text-align: center;
         background: rgba(255,255,255,0.1);
         border-radius: 4px;
         margin-bottom: 10px;
         text-overflow: ellipsis;
          overflow: hidden;
          /* width: 147px; */
          width: 100%;
          white-space: nowrap;
          padding: 0 6px;
}
     
     .linksActive{
      border: 1px solid rgba(255,255,255,.3);
     }
  }
  .links-right{
    width: 82%;
  }
}
.dashline{
  border-left: 1px dashed #eee;
}
.clase-all{
  position: absolute;
  bottom:40px;
  left: 50px;
  z-index: 100;
}
</style>
