<template>
  <div style="display: flex; justify-content: center; align-items: center;" ref="container">

    <iframe
      v-if="isVisible&&element.children[0].dataSources.name == 'scztResultData'"
      ref="iframeElement"
      src="https://nm.pc.vc14.com/demo.html"
      style="width:100%;height:80vh ;"
       
      class="webView"
    ></iframe>
    <div ref="map" class="map" v-if="isVisible&&element.children[0].dataSources.name != 'scztResultData'"></div>
    <el-dialog title="市场主体企业详情" :visible.sync="scztInfoVisible" :modal="false" :before-close="handleClose"
      custom-class="custom-dialog" v-if="this.selectInfo" :append-to-body="true" :lock-scroll="false">
      <!-- 自定义标题样式 -->
      <template #title>
        <span class="dialog-title">市场主体企业详情</span>
      </template>
      <div class="dialog-content">
        <el-table :data="details" style="width: 100%" stripe>
          <el-table-column prop="label" label="属性" width="180">
          </el-table-column>
          <el-table-column prop="value" label="值">
            <template #default="{ row }">
              <div v-if="row.label === '主体图片'">
                <div class="image-gallery">
                  <img v-for="(image, index) in bodyImages" :key="index" :src="image" style="height: 300px;" />
                </div>
              </div>
              <span v-else>{{ row.value }}</span>
            </template>
          </el-table-column>
        </el-table>

      </div>
    </el-dialog>
  </div>
</template>

<script>
import { mapMixin } from './map.mixin.js';
import { lazyMixin } from "../base/lazyload.mixin"
import { data } from 'jquery';
import AMapLoader from '@amap/amap-jsapi-loader';
import { ElButton, ElDialog } from 'element-ui';
import cyjq from "../../mock/home/cyjq.json"
import qhrl from "../../mock/home/qhrl.json"
const axios = require('axios');
export default {
  name: "marker-cluster",
  mixins: [mapMixin, lazyMixin],
  data() {
    return {
   
      markerList: [],
      scztInfoVisible: false,
      selectInfo: null,
      bodyImages: [], // 存储图片 URL 的数组
      mapData: [],
      screenList: [],

      cluster: null,
      gridSize: 120,
      mapType:'',
      zoomLevel:null
    };
  },
  props: {
    data: {
      type: Array,
    },
    element: {
      type: Object,
      default: () => { }
    },
    cardData: {
      type: Object,
      default: () => { }
    },
  },
  beforeUpdate() {
    console.log('Before update:', this.data);
  },
  updated() {
    console.log('Updated:', this.data);
  },
  computed: {
    currentDepartment() {
      return this.$store.getters["schema/schema"].value
    },
  },
  created() {
    let that = this;
    // this.$watch('element', (newVal, oldVal) => {

    //   console.log(newVal)
    //   this.mapType = newVal
    // }, { immediate: true, deep: true });
  },
  mounted() {
    
      
  
  },


  methods: {
    handleClose() {
      this.scztInfoVisible = false
    },

    // 聚拢
    gather() {
      var points = []
      var cluster;
      this.screenList.forEach(item => {
        points.push({
          lnglat: [item.subjectLongitude||item['实际地址_log_3432064'], item.subjectLatitude||item['实际地址_lat_3432064']],
          data:item
        });
      })
      if (cluster) {
        cluster.setMap(null);
      }
      var self = this;
      cluster = new AMap.MarkerCluster(window.map, points, 
      { gridSize: 150, 
        renderClusterMarker: self._renderClusterMarker, // 自定义聚合点样式
        renderMarker: self._renderMarker, });
        cluster.on('click', function (e) {
        self.showInfoDialog(e.clusterData[0].data)
       

      });

    },
    _renderClusterMarker(context) {
  
        var factor = Math.pow(context.count / this.screenList.length, 1 / 18);
        var div = document.createElement('div');
        var Hue = 180 - factor * 180;
        var bgColor = 'hsla(' + Hue + ',100%,40%,0.7)';
        var fontColor = 'hsla(' + Hue + ',100%,90%,1)';
        var borderColor = 'hsla(' + Hue + ',100%,40%,1)';
        var shadowColor = 'hsla(' + Hue + ',100%,90%,1)';
        div.style.backgroundColor = bgColor;
        var size = Math.round(30 + Math.pow(context.count / this.screenList.length, 1 / 5) * 20);
        div.style.width = div.style.height = size + 'px';
        div.style.border = 'solid 1px ' + borderColor;
        div.style.borderRadius = size / 2 + 'px';
        div.style.boxShadow = '0 0 5px ' + shadowColor;
        div.innerHTML = context.count;
        div.style.lineHeight = size + 'px';
        div.style.color = fontColor;
        div.style.fontSize = '14px';
        div.style.textAlign = 'center';
        context.marker.setOffset(new AMap.Pixel(-size / 2, -size / 2));
        context.marker.setContent(div)
    },
    _renderMarker(context) {
        var content = '<div style="background-color: hsla(180, 100%, 50%, 0.3); height: 18px; width: 18px; border: 1px solid hsl(180, 100%, 40%); border-radius: 12px; box-shadow: hsl(180, 100%, 50%) 0px 0px 3px;"></div>';
        var offset = new AMap.Pixel(-9, -9);
        context.marker.setContent(content)
        context.marker.setOffset(offset)
    },

    setData: function () {
      /**
        * LabelsLayer
        */

      // 创建 AMap.LabelsLayer 图层
      var layer = new AMap.LabelsLayer({
        zooms: [3, 20],
        zIndex: 1000,
        collision: false
      });

      // 将图层添加到地图
      window.map.add(layer);
      let self = this
      var icon = {
        type: 'image',
        image: require('../../../../assets/images/logo.png'),
        size: [7, 10],
        anchor: 'bottom-center',
      };
      var markers = [];
      var curPosition = null
      for (var i = 0; i < self.screenList.length; i++) {
        var curPosition = self.screenList[i];
        var curData = {
          position: [parseFloat(self.screenList[i].subjectLongitude), parseFloat(self.screenList[i].subjectLatitude)],
          icon
        };

        var labelMarker = new AMap.LabelMarker(curData);
        labelMarker.on('click', function (e) {
          // console.log(e)
          self.showInfoDialog(curPosition)
          // self.openInfo(item,labelMarker.getPosition())

        });

        markers.push(labelMarker);
      }
      // 一次性将海量点添加到图层
      layer.add(markers);

    },
    zoomChnage() {
      console.log()
      this.zoomLevel  = map.getZoom();
      
    },
    // 范围
    executeConditionRender() {
      let screenCoordinateRange = window.map.getBounds()   //getBounds获取当前地图视图范围/可视区域。返回边界经纬度

      let northEast = [screenCoordinateRange.northEast.lng, screenCoordinateRange.northEast.lat]
      let southEast = [screenCoordinateRange.southWest.lng, screenCoordinateRange.northEast.lat]
      let southWest = [screenCoordinateRange.southWest.lng, screenCoordinateRange.southWest.lat]
      let northWest = [screenCoordinateRange.northEast.lng, screenCoordinateRange.southWest.lat]

      this.screenList = this.mapData.filter(item => {  //选择在浏览器视框区域内点位数据，this.points保存的是所有的点位数据

        return item != undefined ? AMap.GeometryUtil.isPointInRing([parseFloat(item.subjectLongitude)||parseFloat(item['实际地址_log_3432064']), parseFloat(item.subjectLatitude)||parseFloat(item['实际地址_lat_3432064'])], [northEast, southEast, southWest, northWest]) : false
      })

      this.gather()


    },

    showInfoDialog(e) {
      console.log(e)
      this.scztInfoVisible = true;
      this.selectInfo = e
    },

    openInfo(obj, lnglat) {
      console.log(obj)
      //构建信息窗体中显示的内容
      // debugger
      var info = [];
      info.push("<div class='input-card content-window-card'>" +
        "<div><h3>" + obj.bodyName + "</h3></div> ");
      info.push("<div style=\"padding:7px 0px 0px 0px;\"><h4>负责人：" + obj.contactName + "</h4>");
      info.push("<p class='input-item'>主营 : " + (obj.farmingProduct || '') + "   </p>");
      info.push("<p class='input-item'>电话 : " + (obj.corpPhone || '') + "   地区 : " + obj.city + "</p>");
      info.push("<p class='input-item'>地址 :" + (obj.address || '') + "</p></div></div>");
      info.push("<img src='" + obj.bodyCertificateImage + "' height='300' />");



      let infoWindow = new AMap.InfoWindow({
        content: info.join("")  //使用默认信息窗体框样式，显示信息内容
      });

      infoWindow.open(window.map, lnglat);
    },

    loadData() {

      var that = this;
      var style = {
        url: require('../../../../assets/images/logo.png'), //图标地址

        size: new AMap.Size(11, 11), //图标大小
        anchor: new AMap.Pixel(5, 5), //图标显示位置偏移量，基准点为图标左上角
      };

      // that.points.data.map((item, index) => {

      //   let tempItem = {
      //     lnglat: [parseFloat(item.subjectLongitude), parseFloat(item.subjectLatitude)],
      //     data: item,
      //   }

      //   that.markerList.push(tempItem)


      // })

      var style = {
        url: require('../../../../assets/images/logo.png'), //图标地址

        size: new AMap.Size(11, 11), //图标大小
        anchor: new AMap.Pixel(5, 5), //图标显示位置偏移量，基准点为图标左上角
      };

      var massMarks = new AMap.MassMarks(that.points, {
        zIndex: 30, //海量点图层叠加的顺序
        zooms: [3, 19], //在指定地图缩放级别范围内展示海量点图层
        style: style, //设置样式对象
      });
      massMarks.on('click', e => {
        that.openInfo(e, e.data.lnglat)
      })

      massMarks.setMap(window.map);

      /**
       * LabelsLayer
       */

      // 创建 AMap.LabelsLayer 图层
      // var layer = new AMap.LabelsLayer({
      //   zooms: [3, 20],
      //   zIndex: 1000,
      //   collision: false
      // });

      // // 将图层添加到地图
      // that.map.add(layer);

      // var icon = {
      //   type: 'image',
      //   image: 'https://nmtwfcstorage.yunshangwulian.vip/dian1.png',
      //   size: [7, 10],
      //   anchor: 'bottom-center',
      // };

      // that.mapData.data.map((item, index) => {

      //   // let tempItem = {
      //   //   lnglat: [parseFloat(item.subjectLongitude), parseFloat(item.subjectLatitude)],
      //   //   data: item,
      //   // }
      //   const labelMarker = new AMap.LabelMarker({
      //     name: "标注", //此属性非绘制文字内容，仅为标识使用
      //     position: [parseFloat(item.subjectLongitude), parseFloat(item.subjectLatitude)],
      //     zIndex: 1000,
      //     rank: 1, //避让优先级
      //     icon: icon, //标注图标，将 icon 对象传给 icon 属性
      //     // text: text, //标注文本，将 text 对象传给 text 属性
      //   });

      //   that.markerList.push(tempItem)


      // })


      // // 一次性将海量点添加到图层
      // layer.add(that.markerList);

    },

    /**所属行业 */
    setCprz(num) {
      if (num == '1') {
        return '绿色'
      } else if (num == '2') {
        return '有机'
      } else if (num == '3') {
        return '地理标识'
      } else if (num == '4') {
        return '区域共享品牌'
      } else if (num == '5') {
        return '名特优新'
      }

    },
    setSshy(num) {
      if (num == '1') {
        return '种植业'
      } else if (num == '2') {
        return '畜牧业'
      } else if (num == '3') {
        return '水产业'
      } else if (num == '4') {
        return '其他行业'
      }
    },
    setStatus(num) {
      if (num == '0') {
        return '正常'
      } else if (num == '1') {
        return '注销'
      } else if (num == '3') {
        return '停产'
      }
    },
    setBodyAttr(num) {
      if (num == '1') {
        return '生产主体'
      } else if (num == '2') {
        return '屠宰场'
      } else if (num == '3') {
        return '生鲜乳企业'
      } else if (num == '4') {
        return '投入品经营主体'
      } else if (num == '5') {
        return '投入品经营主体'
      }
    },
    setqylx(num) {
      if (num == '1') {
        return '企业'
      } else if (num == '2') {
        return '合作社'
      } else if (num == '3') {
        return '家庭农场'
      } else if (num == '4') {
        return '个体小散户'
      } else if (num == '5') {
        return '种植大户'
      } else if (num == '6') {
        return '其它'
      }
    },
  },
  computed: {

    details() {
      return [
        { label: '主体名称', value: this.selectInfo.bodyName },
        { label: '产品认证', value: this.setCprz(this.selectInfo.approve) },
        { label: '行政区划', value: this.selectInfo.area },
        { label: '详细地址', value: this.selectInfo.address },
        { label: '主体所属行业', value: this.setSshy(this.selectInfo.business) },
        { label: '营业期限', value: this.selectInfo.businessTerm },
        { label: '主体状态', value: this.setStatus(this.selectInfo.cancel) },
        { label: '主体属性', value: this.setBodyAttr(this.selectInfo.subjectAttribute) },
        { label: '企业类型', value: this.setqylx(this.selectInfo.enterpriseType) },
        { label: '是否龙头企业', value: this.selectInfo.isLeadingEnterprise === '0' ? '否' : '是' },
        { label: '规模', value: this.selectInfo.farmingScale },
        { label: '组织机构代码证', value: this.selectInfo.orgCode },
        { label: '生产产品', value: this.selectInfo.farmingProduct },
        { label: '联系人', value: this.selectInfo.contactName },
        { label: '联系人电话', value: this.selectInfo.contactPhone },
        { label: '法人', value: this.selectInfo.corpName },
        { label: '法人电话', value: this.selectInfo.corpPhone },
        { label: '主体图片', value: '' }, // 添加图片展示行
      ];
    },

    splitBodyImages() {
      if (this.selectInfo && this.selectInfo.bodyImage) {
        const images = this.selectInfo.bodyImage.split(',');
        const host = 'https://www.nmgnxza.cn:63';

        // 处理第一个 URL
        let firstImageUrl = images[0];
        if (!firstImageUrl.startsWith('http')) {
          firstImageUrl = `${host}/${firstImageUrl}`;
        }

        // 处理剩余的 URL
        const otherImages = images.slice(1).map(imageUrl => `${host}/${imageUrl}`);

        return [firstImageUrl, ...otherImages];
      }
      return [];
    },
  },
  watch: {

    splitBodyImages(newVal) {
      this.bodyImages = newVal;
    },
    element:{
      handler(newVal,oldVal){
        
        let mapType = newVal.children[0].dataSources.name
       
          if(mapType == 'cyjqResultData'){
             this.mapData = cyjq
        }else if(mapType == 'qhrlResultData'){
             this.mapData = qhrl.data.data
             console.log(this.mapData)
        }
      },
      immediate: true
      
  },
}

};
</script>

<style scoped lang="scss">
.map {
  width: 100%;
  height: calc(100vh - 260px);
  color: #000A25 !important;
  transform: translateZ(0);
}

.input-card {
  color: #000A25;
}

.custom-dialog {
  width: 80%;
  max-width: 800px;
  background-color: #f9f9f9;
  border-radius: 10px;
}

.dialog-title {

  font-size: 1.2em;
  color: #333;
}

.dialog-content {
  max-height: 500px;
  /* 可根据实际情况调整 */
  overflow-y: auto;
  padding: 20px;
  background-color: #fff;
  border-radius: 5px;
}

.dialog-item {
  margin-bottom: 10px;
}

.image-gallery {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  gap: 10px;
  margin-top: 20px;
}

.image-gallery img {
  margin: 5px;
  border: 1px solid #ddd;
  border-radius: 5px;
}

.dialog-footer {
  text-align: right;
}

/* 为按钮添加样式 */
.el-button {
  margin-top: 10px;

}
</style>
